import { withTheme, DefaultTheme } from 'styled-components'
import isPropValid from '@emotion/is-prop-valid'

declare module 'styled-components' {
  export interface DefaultTheme {
    roundCornerInsideTooltips: boolean
    selectableListOnComputerWithOuterBorder: boolean
    colors: typeof theme['colors']
    breakpoints: typeof theme['breakpoints']
    castingPluginLayout: 'row' | 'two_columns'
    castingPluginDeployedStepAnchor?: HTMLDivElement
  }
}

export const theme = {
  get roundCornerInsideTooltips () { return window.innerWidth < this.breakpoints.m },
  selectableListOnComputerWithOuterBorder: true,
  castingPluginLayout: 'row' as const,
  breakpoints: {
    s: 480,
    m: 768,
    l: 959
  },
  colors: {
    clr_error: '#c0392b',
    clr_warning: '#EFB036',
    clr_warning_darker: '#BB8826',
    clr_alert: '#FCF8E3',
    clr_success: '#5CB85C',
    clr_0_dark: '#384248',
    clr_0: '#718491',
    clr_0_light: '#BAC4CB',
    clr_0_lighten: '#BAC4CB',
    clr_0_lighten_altenate: '#D5DADE',
    clr_0_lightest: '#F0F4F6',
    clr_0_lightest_alternate: '#E4E9EC',
    clr_1: '#5DBCBE',
    clr_1_light: '#8ED0D2',
    clr_1_darken: '#27A2A5',
    clr_2: '#EFB036',
    clr_3: '#3771C8',
    clr_3_light: '#87AADE',
    clr_3_lighten: '#EAF0F8',
    clr_divider: '#D9E2E7'
  }
}

export function personalizedTheme (marketColors: Partial<typeof theme['colors']>) {
  return { ...theme, colors: { ...theme.colors, ...marketColors } }
}

export function injectTheme<T> (component: T) {
  return withTheme(component as any) as any as T & { theme: DefaultTheme }
}

export function isPropValidForForwarding (propName: string, target: unknown) {
  return typeof target !== 'string' || isPropValid(propName)
}
